// src/App.js
import React from 'react';
import CorporateRegistrationForm from './CorporateRegistrationForm';

const App = () => {
  return (
    <div className="App">
      <h1>Corporate Registration</h1>
      <CorporateRegistrationForm />
    </div>
  );
};

export default App;
