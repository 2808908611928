import React from 'react';

const MyComponent = () => {
  return (
    <div>
      <noscript>
        <strong>We're sorry but the system doesn't work properly without JavaScript enabled. Please enable it to continue.</strong>
      </noscript>
      <div id="loading-bg" style={{ display: 'none' }}>
        <div className="loading-container">
          <div className="image">
            <img id="loading-logo-icon" src="https://account.bnk2.com/logos/bnk2-logo.png" alt=" " style={{ display: 'block' }} />
          </div>
          <div className="loader"></div>
        </div>
      </div>
      <div id="app">
        <div className="layout--full-page">
          <div className="flex w-full bg-img vx-row no-gutter items-center justify-center" style={{ backgroundImage: 'none !important' }}>
            <div className="vx-col mt-12 sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5">
              <div className="vx-card">
                <div className="vx-card__collapsible-content vs-con-loading__container">
                  <div className="full-page-bg-color">
                    <div className="vx-row no-gutter">
                      <div className="vx-col cover-pos hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto" style={{ backgroundImage: 'url(https://account.bnk2.com/logos/login_pic_3.jpg)', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'cover', borderRadius: '6px 0 0 6px' }}>
                      </div>
                      <div className="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg" style={{ borderRadius: '6px' }}>
                        <div className="sm-logo-styling logo-auth">
                          <img src="https://account.bnk2.com/logos/bnk2-logo.png" style={{ maxWidth: '114px', marginLeft: '2rem', marginTop: '13px' }} />
                        </div>
                        <div className="p-8">
                          <div className="vx-card__title">
                            <h4 className="mb-4">Create Account</h4>
                          </div>
                          <div className="clearfix">
                            <div className="con-vs-tabs vs-tabs vs-tabs-primary vs-tabs-position-top" vs-alignment="fixed">
                              <div className="con-ul-tabs">
                                <ul className="ul-tabs vs-tabs--ul ul-tabs-fixed">
                                  <li className="vs-tabs--li">
                                    <button type="button" className="vs-tabs--btn" style={{ display: 'flex', alignItems: 'center' }}>
                                      <i className="vs-icon notranslate icon-scale material-icons null vs-icon-primary" style={{ paddingRight: '9px' }}>account_circle</i>
                                      <span>Individual</span>
                                    </button>
                                  </li>
                                  <li className="vs-tabs--li activeChild">
                                    <button type="button" className="vs-tabs--btn" style={{ display: 'flex', alignItems: 'center' }}>
                                      <i className="vs-icon notranslate icon-scale material-icons null vs-icon-primary" style={{ paddingRight: '9px' }}>store</i>
                                      <span>Corporate</span>
                                    </button>
                                  </li>
                                </ul>
                                <span className="line-vs-tabs" style={{ left: '168px', width: '168px', height: '2px', transform: 'scaleX(1)' }}></span>
                              </div>
                              <div className="con-slot-tabs">
                                <div className="con-tab vs-tabs--content">
                                  <div>
                                    <div className="vs-component vs-con-input-label vs-input w-full vs-input-primary is-label-placeholder">
                                      <div className="vs-con-input">
                                        <input type="text" data-vv-validate-on="blur" name="First Name" className="vs-inputx vs-input--input normal" style={{ border: '1px solid rgba(0, 0, 0, 0.2)' }} />
                                        <span className="input-span-placeholder vs-input--placeholder normal normal vs-placeholder-label">First Name</span>
                                      </div>
                                      <span></span>
                                    </div>
                                    <span className="text-danger text-sm"></span>
                                    <div className="vs-component vs-con-input-label vs-input w-full vs-input-primary is-label-placeholder">
                                      <div className="vs-con-input">
                                        <input type="text" data-vv-validate-on="blur" name="Last Name" className="vs-inputx vs-input--input normal" style={{ border: '1px solid rgba(0, 0, 0, 0.2)' }} />
                                        <span className="input-span-placeholder vs-input--placeholder normal normal vs-placeholder-label">Last Name</span>
                                      </div>
                                      <span></span>
                                    </div>
                                    <span className="text-danger text-sm"></span>
                                    <div className="flex my-4" value="">
                                      <div className="w-1/3">
                                        <div tabIndex="0" className="countryContainer">
                                          <div className="arrow">▼</div>
                                          <input type="text" className="d-if-collapsed type-start" />
                                        </div>
                                        <div className="listFlags" style={{ display: 'none' }}>
                                          <ul>
                                            <li className="li-style"><span className="flag flag-af small-flag"></span> Afghanistan +93</li>
                                            {/* Add other countries similarly */}
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="ml-2 w-2/3">
                                        <div className="vs-component vs-con-input-label vs-input w-full vs-input-primary">
                                          <div className="vs-con-input">
                                            <input type="text" disabled="disabled" maxLength="15" className="vs-inputx vs-input--input normal" style={{ border: '1px solid rgba(0, 0, 0, 0.2)' }} />
                                            <span className="input-span-placeholder vs-input--placeholder normal">Phone Number</span>
                                          </div>
                                          <span></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <span>
                                        <label className="label-register">Birth Date</label>
                                        <br />
                                      </span>
                                      <div className="flex justify-start">
                                        <div className="w-24 mr-6">
                                          <div className="con-select selectExample" name="day" placeholder="Day">
                                            <div className="input-select-con">
                                              <input readOnly type="text" name="day" placeholder="Day" className="input-select vs-select--input" />
                                              <button className="icon-select-clear vs-select--icon-clear"><i className="material-icons">close</i></button>
                                              <i className="vs-icon notranslate icon-scale icon-select vs-select--icon material-icons null">keyboard_arrow_down</i>
                                              <div className="vs-select--options vs-select-primary" style={{ display: 'none' }}>
                                                <ul>
                                                  <li data-text="01" className="vs-component"><button type="button" name="button" className="vs-select--item"><span>01</span></button></li>
                                                  {/* Add other days similarly */}
                                                </ul>
                                              </div>
                                            </div>
                                            <span></span>
                                          </div>
                                        </div>
                                        <div className="w-24 mr-6">
                                          <div className="con-select selectExample" name="month" placeholder="Month">
                                            <div className="input-select-con">
                                              <input readOnly type="text" name="month" placeholder="Month" className="input-select vs-select--input" />
                                              <button className="icon-select-clear vs-select--icon-clear"><i className="material-icons">close</i></button>
                                              <i className="vs-icon notranslate icon-scale icon-select vs-select--icon material-icons null">keyboard_arrow_down</i>
                                              <div className="vs-select--options vs-select-primary" style={{ display: 'none' }}>
                                                <ul>
                                                  <li data-text="January" className="vs-component"><button type="button" name="button" className="vs-select--item"><span>January</span></button></li>
                                                  {/* Add other months similarly */}
                                                </ul>
                                              </div>
                                            </div>
                                            <span></span>
                                          </div>
                                        </div>
                                        <div className="w-24 mr-6">
                                          <div className="con-select selectExample" name="year" placeholder="Year">
                                            <div className="input-select-con">
                                              <input readOnly type="text" name="year" placeholder="Year" className="input-select vs-select--input" />
                                              <button className="icon-select-clear vs-select--icon-clear"><i className="material-icons">close</i></button>
                                              <i className="vs-icon notranslate icon-scale icon-select vs-select--icon material-icons null">keyboard_arrow_down</i>
                                              <div className="vs-select--options vs-select-primary" style={{ display: 'none' }}>
                                                <ul>
                                                  <li data-text="2006" className="vs-component"><button type="button" name="button" className="vs-select--item"><span>2006</span></button></li>
                                                  {/* Add other years similarly */}
                                                </ul>
                                              </div>
                                            </div>
                                            <span></span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="vs-component vs-con-input-label vs-input w-full vs-input-primary is-label-placeholder">
                                      <div className="vs-con-input">
                                        <input type="text" data-vv-validate-on="blur" name="Corporate Legal Name" className="vs-inputx vs-input--input normal" style={{ border: '1px solid rgba(0, 0, 0, 0.2)' }} />
                                        <span className="input-span-placeholder vs-input--placeholder normal normal vs-placeholder-label">Corporate legal name</span>
                                      </div>
                                      <span></span>
                                    </div>
                                    <span className="text-danger text-sm"></span>
                                    <div className="country-select">
                                      <div className="ui fluid search selection dropdown">
                                        <i className="dropdown icon"></i>
                                        <input autoComplete="off" tabIndex="0" name="" className="search" />
                                        <div className="text default">Select Corporate Country</div>
                                        <div tabIndex="-1" className="menu hidden" style={{ display: 'none' }}>
                                          <div className="item">Afghanistan</div>
                                          {/* Add other countries similarly */}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="vs-component vs-con-input-label vs-input w-full vs-input-primary is-label-placeholder">
                                      <div className="vs-con-input">
                                        <input type="text" data-vv-validate-on="blur" name="Corporate Registration Number" className="vs-inputx vs-input--input normal" style={{ border: '1px solid rgba(0, 0, 0, 0.2)' }} />
                                        <span className="input-span-placeholder vs-input--placeholder normal normal vs-placeholder-label">Corporate Registration Number</span>
                                      </div>
                                      <span></span>
                                    </div>
                                    <span className="text-danger text-sm"></span>
                                    <div className="vs-component vs-con-input-label vs-input w-full mt-6 vs-input-primary is-label-placeholder">
                                      <div className="vs-con-input">
                                        <input type="email" data-vv-validate-on="blur" name="Email" className="vs-inputx vs-input--input normal hasValue" style={{ border: '1px solid rgba(0, 0, 0, 0.2)' }} />
                                        <span className="input-span-placeholder vs-input--placeholder normal normal vs-placeholder-label">Email</span>
                                      </div>
                                      <span></span>
                                    </div>
                                    <span className="text-danger text-sm"></span>
                                    <div className="vs-component vs-con-input-label vs-input w-full mt-6 vs-input-primary is-label-placeholder">
                                      <div className="vs-con-input">
                                        <input type="password" data-vv-validate-on="blur" name="Password" className="vs-inputx vs-input--input normal hasValue" style={{ border: '1px solid rgba(0, 0, 0, 0.2)' }} />
                                        <span className="input-span-placeholder vs-input--placeholder normal normal vs-placeholder-label">Password</span>
                                      </div>
                                      <span></span>
                                    </div>
                                    <span className="text-danger text-sm"></span>
                                    <div className="vs-component vs-con-input-label vs-input w-full mt-6 vs-input-primary is-label-placeholder">
                                      <div className="vs-con-input">
                                        <input type="password" data-vv-validate-on="blur" data-vv-as="password" name="Confirm Password" className="vs-inputx vs-input--input normal" style={{ border: '1px solid rgba(0, 0, 0, 0.2)' }} />
                                        <span className="input-span-placeholder vs-input--placeholder normal normal vs-placeholder-label">Confirm Password</span>
                                      </div>
                                      <span></span>
                                    </div>
                                    <span className="text-danger text-sm"></span>
                                    <div className="flex justify-start">
                                      <div>
                                        <div className="vs-component con-vs-checkbox mt-6 vs-checkbox-primary vs-checkbox-default">
                                          <input type="checkbox" className="vs-checkbox--input" value="false" />
                                          <span className="checkbox_x vs-checkbox" style={{ border: '2px solid rgb(180, 180, 180)' }}>
                                            <span className="vs-checkbox--check"><i className="vs-icon notranslate icon-scale vs-checkbox--icon  material-icons null">check</i></span>
                                          </span>
                                          <span className="con-slot-label">I accept the</span>
                                        </div>
                                      </div>
                                      <div className="termConditionLink">
                                        <div><a target="_blank" href="https://bnk2.com/legal/terms-of-use">terms &amp; conditions.</a></div>
                                      </div>
                                    </div>
                                    <button name="button" className="vs-component vs-button mt-6 vs-button-primary vs-button-border" style={{ background: 'transparent' }}>
                                      <span className="vs-button-backgroundx vs-button--background" style={{ opacity: '1', left: '20px', top: '20px', width: '0px', height: '0px', transition: 'width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s' }}></span>
                                      <span className="vs-button-text vs-button--text">Login</span>
                                      <span className="vs-button-linex" style={{ top: 'auto', bottom: '-2px', left: '50%', transform: 'translate(-50%)' }}></span>
                                    </button>
                                    <button name="button" disabled="disabled" className="vs-component vs-button float-right mt-6 vs-button-primary vs-button-filled">
                                      <span className="vs-button-backgroundx vs-button--background" style={{ opacity: '1', left: '20px', top: '20px', width: '0px', height: '0px', transition: 'width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s' }}></span>
                                      <span className="vs-button-text vs-button--text">Register</span>
                                      <span className="vs-button-linex" style={{ top: 'auto', bottom: '-2px', left: '50%', transform: 'translate(-50%)' }}></span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
                    <div className="flex-wrap justify-between">
                      <footer>
                        <div className="vs-component vs-divider">
                          <span className="vs-divider-border after" style={{ width: '100%', borderTop: '1px solid rgba(0, 0, 0, 0.1)' }}></span>
                          <span className="vs-divider-border before" style={{ width: '100%', borderTop: '1px solid rgba(0, 0, 0, 0.1)' }}></span>
                        </div>
                        <span>
                          <p className="footer-content">Issuance Scheme Limited, trading as bnk² and bnk2, Companies House UK Number 12192452, is an EMD Agent that is registered and authorised by the Financial Conduct Authority under the Electronic Money Regulations 2011, FCA Reference Number 902802, for the distribution of electronic money and payment processing.</p>
                        </span>
                      </footer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <script src="/js/chunk-vendors.d73ce4b9.js"></script>
          <script src="/js/app.5fde0ab0.js"></script>
        </div>
      </div>
    </div>
  );
};

export default MyComponent;

